import AuthService, { AuthEvents, Bootstrap } from '@hotmart/cas-js'

export default (context, inject) => {
  const { app, $config, $axios } = context

  const authService = new AuthService({
    env: $config.environment,
    client_id: $config.authClientID,
    scope: 'openid profile user email address authorities phone',
    authority: $config.authorityCas,
    automaticSilentRenew: true,
    revalidateOnFocus: true,
  })

  const bootstrap = new Bootstrap(authService, { appName: 'app-helpcenter' })

  bootstrap.start({
    signinRedirect: false,
  })

  AuthEvents.addListenUser(({ detail: user }) => {
    app.store.dispatch('casUser/setUserCas', user)

    const token = user?.id_token
    $axios.setToken(token, 'Bearer')
  })

  AuthEvents.addListenRenewError(e => {
    if (e.detail === 'login_required') {
      app.store.dispatch('casUser/setUserCas', {})
      localStorage.clear()
      $axios.setToken(false)
    }
  })

  inject('cas', { bootstrap: bootstrap, authService: authService })
}

export default (context, inject) => {
  const { app } = context

  function checkLogged() {
    const user = app.store.getters['casUser/userCas']

    return Boolean(user)
  }

  inject('checkLogged', checkLogged)
}

import { LANGUAGE } from '~/enum/language'
import LocaleService from '~/services/LocaleService'

export default function ({ $sentry, $axios, $config, app, store, route, req, params }) {
  delete $axios.defaults.headers.common.cookie

  let localeService = new LocaleService({ app, store, route, req, params })
  let locale = localeService.getLocale().toLowerCase()

  $axios.setBaseURL($config.apiUrl)
  $axios.setHeader('Accept-Language', locale)

  $axios.onRequest(config => {
    const supportedLangs = Object.values(LANGUAGE)
    const currentLang = config.headers.common['Accept-Language']
    if (!currentLang) {
      const fallback = locale || navigator.languages.find(lang => supportedLangs.includes(lang))
      config.headers.common['Accept-Language'] = fallback
    }

    return config
  })

  $axios.onResponse(response => {
    store.dispatch('header/setAlertInstability', [false])

    return response.data
  })
  $axios.onError(error => {
    $sentry.captureException?.(error)
    error = error.response ? error.response : error

    if ([500, 404, 400].includes(error?.status)) {
      store.dispatch('header/setAlertInstability', [true, 'alertInstability'])
    }

    if (process.server) {
      return Promise.resolve(false)
    }
  })
}

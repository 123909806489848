import { isString } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'

export default {
  form: state => state.form,
  fields: state => state.form?.$children || [],
  fieldsInStep: (state, getters) => selector =>
    getters.fields.filter(({ $el }) => $el.closest?.(selector)).filter(Boolean),
  currentStepIndex: state => state.currentStepIndex,
  hasFieldsInStep: (state, getters) => selector => getters.fieldsInStep(selector).length > 0,
  hasMissingRequiredFieldsInStep: (state, getters) => selector => {
    const fields = getters.fieldsInStep(selector)

    return fields.some(vueComponent => {
      const isRequired = vueComponent.required

      // Elements with the data-step-nav-disable-prevent attribute prevent the step button from being disabled
      const preventsDisabling = 'stepNavDisablePrevent' in vueComponent.$el.dataset

      if (!isRequired || preventsDisabling) {
        return false
      }

      // TODO: Once all instances of hc-input-file are replaced with hc-input-file-v2,
      //       remove the usage of vueComponent.attachments?.attachments. This refactor
      //       will simplify the value assignment logic. (Added by P.L. on 04/06/2024)
      const value = vueComponent.attachments?.attachments || vueComponent.value
      const isValueMissig = isObject(value) ? isEmpty(value) : isString(value) ? !value.trim() : !value

      return isValueMissig
    })
  },
  isValidStep: (state, getters) => selector => getters.form.isValidForm(getters.fieldsInStep(selector)),
}
